var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Results")]),_c('el-divider'),_c('div',[_c('el-radio-group',{model:{value:(_vm.testType),callback:function ($$v) {_vm.testType=$$v},expression:"testType"}},[_c('el-radio-button',{attrs:{"label":"SAT"}}),_c('el-radio-button',{attrs:{"label":"ACT"}})],1)],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%","margin-bottom":"30px"},attrs:{"data":_vm.tests,"border":""}},[_c('el-table-column',{attrs:{"prop":"full_name","label":"Test Name"}}),_c('el-table-column',{attrs:{"prop":"title","label":"Test ID","width":"180"}}),_c('el-table-column',{attrs:{"label":"Score","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam_id > 0)?_c('router-link',{attrs:{"to":{
              name: 'TestResult',
              query: {
                user_exam_id: scope.row.user_exam_id
              },
              params: {
                testType: _vm.testType.toLowerCase()
              }
            },"tag":"a","target":"_blank"}},[_vm._v(" 1600 ")]):_c('span',[_vm._v(" 1600 ")])]}}])}),_c('el-table-column',{attrs:{"label":"Date","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam_id > 0)?_c('span',[_vm._v(" "+_vm._s(scope.row.date)+" ")]):_c('span',[_vm._v(" 2020-09-23 ")])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"View","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.viewAnswer(scope.row)}}},[_c('i',{staticClass:"fa fa-eye"})])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }