<template>
  <div class="container">
    <h2>Results</h2>
    <el-divider></el-divider>
    <div>
      <el-radio-group v-model="testType">
        <el-radio-button label="SAT"></el-radio-button>
        <el-radio-button label="ACT"></el-radio-button>
      </el-radio-group>
    </div>
    <div>
      <el-table :data="tests" border style="width: 100%;margin-bottom:30px">
        <el-table-column prop="full_name" label="Test Name"></el-table-column>
        <el-table-column
          prop="title"
          label="Test ID"
          width="180"
        ></el-table-column>
        <el-table-column label="Score" width="180">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'TestResult',
                query: {
                  user_exam_id: scope.row.user_exam_id
                },
                params: {
                  testType: testType.toLowerCase()
                }
              }"
              tag="a"
              v-if="scope.row.user_exam_id > 0"
              target="_blank"
            >
              <!-- {{ scope.row.score }} -->
              1600
            </router-link>
            <span v-else>
              <!-- N/A -->
              1600
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Date" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.user_exam_id > 0">
              {{ scope.row.date }}
            </span>
            <span v-else>
              <!-- N/A -->
              2020-09-23
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="count" label="Count" width="100">
          2
        </el-table-column> -->
        <el-table-column fixed="right" label="View" width="80">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="viewAnswer(scope.row)">
              <i class="fa fa-eye"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/tests.js";
export default {
  metaInfo() {
    return {
      title: "Results - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      testType: "SAT",
      tests: []
    };
  },
  computed: {},
  watch: {
    testType: {
      handler() {
        this.getTests();
      }
    }
  },

  mounted() {
    this.getTests();
  },

  methods: {
    async getTests() {
      let tests = {};
      if (this.testType == "SAT") {
        tests = await TOEFL.getSats();
        tests = tests["sats"];
      } else if (this.testType == "ACT") {
        tests = await TOEFL.getActs();
      }
      this.tests = tests;
    },
    async startGrading() {
      let url_name = "Grading" + this.form.type;
      let test = this.form.test;
      let type = "sats";
      if (this.form.type == "SAT") {
        type = "sats";
      } else if (this.form.type == "ACT") {
        type = "acts";
      }
      let exam_id = await TOEFL.getExamId(type, {
        title: test
      });
      this.routerPush({
        name: url_name,
        query: {
          setKeyboard: this.form.keyboard,
          exam_id: exam_id
        }
      });
    },
    viewAnswer(row) {
      console.log(row);
    }
  }
};
</script>

<style scoped>
.container {
  padding: 20px 0;
}
</style>
